import React from "react"
import ReactDOM from "react-dom";
import { Formik, Field, Form } from "formik"

import { FormTypes, FormType } from "./types"



export const GlobalForm = (params: FormTypes) => {
    const { submitTitle, submitCss, postSubmit, fields, children } = params
    return (
        <Formik
        initialValues={{ name: "", email: "" }}
        onSubmit={async (values: any) => {
            // await new Promise((resolve) => setTimeout(resolve, 500));
            // alert(JSON.stringify(values, null, 2));
            await postSubmit(values)
        }}
        >
            <Form className="flex flex-col gap-4">
                {
                    fields.map((item: FormType) => {
                        const {name, type, labelCss, inputCss} = item
                        return (<>
                                <label htmlFor={name} className={labelCss}>{name}</label>
                                <Field name={name} type={type} className={inputCss} />
                            </>
                        )
                    })
                }
                {children}
                <button type={"submit"} className={submitCss}>{submitTitle}</button>
            </Form>
        </Formik>
    )
}
