import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


// ----------------------------------------------------------------------

export default function NotFoundView() {
  return (
    <div>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Sorry, Page Not Found!
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          Sorry, we couldn’t find the page you’re looking for. Perhaps you’ve mistyped the URL? Be
          sure to check your spelling.
        </Typography>
      <Button href="/" size="large" variant="contained">
        Go to Home
      </Button>
    </div>
  );
}
