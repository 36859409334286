import React from "react";
import SignInSocials from "../socials";
import coverImage from "../../../assets/imgs/sign-in-up-cover.svg";
import { GlobalForm } from "../../../components/form/input";

const SignUp = () => {
  return (
    <div className="w-[100vw] h-[100vh] grid grid-cols-2">
      <div className="flex items-center justify-center">
        <div className="flex flex-col items-center justify-center col-span-1 max-w-[360px]">
          <img className="h-[47px] w-[47px]" src={"logo192.png"} alt="" />
          <div className="text-[32px] text-[var(--0plus-primary-color-900)] font-bold">
            Create an account
          </div>
          <div className="text-[16px] text-[var(--0plus-gray-color-500)] font-normal mt-3 ">
            Free trial! No credit card required
          </div>
          <div className="mt-8">
            <GlobalForm
              fields={[
                {
                  name: "Email",
                  type: "email",
                  labelCss: "text-sm font-bold",
                  inputCss:
                    "p-3 rounded-sm border border-gray-[var(--0plus-gray-color-300)]",
                },
                {
                  name: "Password",
                  type: "password",
                  labelCss: "text-sm font-bold",
                  inputCss:
                    "p-3 rounded-sm border border-gray-[var(--0plus-gray-color-300)]",
                },
              ]}
              children={
                  <div className="h-10 flex gap-3 text-[14px] text-[var(--0plus-gray-color-600)]">
                    <input id="rv_new_feature" type="checkbox" />
                    I would like to
                    receive emails about new features, product updates and other
                    news.
                </div>
              }
              submitCss="bg-[var(--0plus-primary-color-500)] p-3 text-[var(--0plus-base-color-white)] font-medium rounded"
              submitTitle="Start Free Trial"
              postSubmit={async (values) => {
                console.log(values);
              }}
            />
          </div>
          <div className="max-w-[360px] mt-4">
            <SignInSocials title="Sign up with Google" />
          </div>
          <div className="flex gap-1 text-[var(--0plus-gray-color-600)] text-xs mt-3">
            <div className="">
              By signing up you agree to our 
            </div>
            <a className="text-[var(--0plus-primary-color-500)] underline leading-normal">
                Terms
            </a>
            <div> and </div>
            <a className="text-[var(--0plus-primary-color-500)] underline leading-normal">
                Privacy Policy
            </a>
          </div>
          <div className="text-[14px] text-[var(--0plus-gray-color-600)] font-normal mt-8">
            Already have an account?
            <a className="text-[var(--0plus-primary-color-500)] underline leading-normal font-medium">
                Sign in
            </a>
          </div>
        </div>
      </div>
      <div className="flex col-span-1 overflow-hidden">
        <img className="w-full h-full" src={coverImage} alt="" />
      </div>
    </div>
  );
};

export default SignUp;
